import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PERMISSION } from "../enum";
import { tableData } from "./Years2024/tableInfo";
import { useSelector } from "react-redux";
import BoxWithLabelValue from "../../../components/BoxWithLabelValue";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import FinanceModal from "containers/Finance/component/GalleryModal";
import isoToDate from "utils/isoToDate";
import { changePriceFormat } from "utils/calculators";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { FINANCE_DATE } from "containers/Finance/enum";
import Years2024 from "./Years2024";
import Years2023 from "./Years2023";

const List = () => {
	// const [checkList, setCheckList] = useState();
	// // ---------------------------------------------- new route
	// const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

	// // ---------------------------------------------- Constance
	const adminState = useSelector((state) => state.admin);
	const validPermission = adminState?.data?.permissions === true;
	// const permissions = validPermission || adminState?.data?.permissions?.[PERMISSION]
	// const invoicePermissions = validPermission || adminState?.data?.permissions?.invoice
	// const isBranch = adminState.data.isBranch
	// const isHotel = adminState.data.isHotel
	const isAdmin = adminState.data.isAdmin;

	// const dispatch = useDispatch();
	// const location = useLocation();
	const navigate = useNavigate();
	let { years } = useParams();
	let defaultYears = FINANCE_DATE[FINANCE_DATE.length - 1];
	years = years || defaultYears;
	// let minDate = new Date(years + '-01-01');
	// let maxDate = new Date(years + '-12-31');

	// const QueryString = useQueryString({ sort: "desc", from: minDate });
	// const { page, limit } = QueryString;
	// const { t } = useTranslation();

	// // ---------------------------------------------- fetching functions
	// const getting = async () =>
	//   await axiosClient().get(api[API_NAME].list, {
	//     params: { ...QueryString },
	//   });
	// const changeStatus = async (params) => {
	//   console.log(params, "changeStatus");
	//   await axiosClient().patch(api[API_NAME].changeStatus, params);
	// };

	// const deleting = async (params) => {
	//   await axiosClient().delete(api[API_NAME].delete + "/" + params?.id);
	// };
	// // ------------------------------------------------------- query Get
	// const {
	//   data: { data = {} } = {},
	//   error,
	//   isError,
	//   isLoading,
	//   refetch,
	// } = useQueryCustom({
	//   name: `${API_NAME}_get`,
	//   url: getting,
	//   params: { ...QueryString },
	//   enabled: !!(validPermission || permissions?.list)
	// });
	// // ---------------------------------------------- mutation Change Status
	// const onSuccessDeleteMutation = () => {
	//   toastify.success({ title: "success" });
	// };

	// const deleteMutation = useMutationCustom({
	//   name: `${API_NAME}_delete`,
	//   url: deleting,
	//   invalidQuery: `${API_NAME}_get`,
	//   onSuccess: onSuccessDeleteMutation,
	// });
	// // ---------------------------------------------- mutation Change Status
	// const onSuccessChangeStatusMutation = () => {
	//   toastify.success({ title: "success" });
	// };

	// const changeStatusMutation = useMutationCustom({
	//   name: `${API_NAME}_changeStatus`,
	//   url: changeStatus,
	//   invalidQuery: `${API_NAME}_get`,
	//   onSuccess: onSuccessChangeStatusMutation,
	// });
	// // ---------------------------------------------- logs
	// console.log({ data, error, isError, isLoading });
	// // ---------------------------------------------- action click table

	// const actionsOperation = ({ data, status }) => {
	//   swal({
	//     title: "Are you sure?",
	//     text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]
	//       }`,
	//     icon: "warning",
	//     buttons: true,
	//     dangerMode: true,
	//   }).then((willBe) => {
	//     if (willBe) {
	//       if (status.label === "delete") deleteMutation.mutate({ id: data });
	//       else changeStatusMutation.mutate({ ids: data, isActive: status.value });
	//     }
	//   });
	// };

	// const clickAction = ({ data, status }) => {
	//   const newData = data.map((x) => x.id);
	//   actionsOperation({ data: newData, status });
	// };

	// const handleAction = (status) => {
	//   const data = Object.keys(checkList);
	//   console.log({ data, status });
	//   actionsOperation({ data, status: status });
	// };
	// const exportToCSV = ({ apiData, fileName, head, cols }) => {
	//   const workbook = XLSX.utils.book_new();
	//   // const myHeader = ["id","name","location"];
	//   const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

	//   const range = XLSX.utils.decode_range(worksheet["!ref"]);
	//   range.e["c"] = head.length - 1;
	//   worksheet["!ref"] = XLSX.utils.encode_range(range);
	// worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
	//   worksheet["!cols"] = cols;

	//   XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
	//   XLSX.writeFile(workbook, fileName + ".xlsx");

	//   // const ws = XLSX.utils.json_to_sheet(apiData);
	//   // /* custom headers */
	//   // XLSX.utils.sheet_add_aoa(ws, [head], { origin: "A1", });
	//   // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
	//   // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
	//   // const data = new Blob([excelBuffer], { type: fileType });
	//   // FileSaver.saveAs(data, fileName + fileExtension);
	// };

	// const handleGetFullDataWithDate = () => {
	//   let cols = [
	//     { wch: 30 },
	//     { wch: 15 },
	//     { wch: 20 },
	//     { wch: 20 },
	//     { wch: 25 },

	//   ];
	//   axiosClient()
	//     .get(api[API_NAME].list, {
	//       params: { ...QueryString, limit: 0, page: 1 },
	//     })
	//     .then((x) => {
	//       console.log({ x });
	//       let head = [
	//         t("name"),
	//         t("orderNumber"),
	//         t("commission"),
	//         t("price"),
	//         t("createdAt"),
	//       ];
	//       let data = []

	//       if (isAdmin) {
	//         head = [
	//           t("name"),
	//           t("commission"),
	//           t("orderCount"),
	//           t("totalAmount"),
	//           t("totalCommission"),
	//         ]

	//         data = x?.data?.result?.map((y) => ({
	//           [t("name")]: y?.hotel?.name,
	//           [t("commission")]: y?.hotel?.commission,
	//           [t("orderCount")]: y?.orderCount,
	//           [t("totalAmount")]: y?.totalAmount,
	//           [t("totalCommission")]: y?.totalCommission,
	//         }));
	//       } else {
	//         data = x?.data?.result?.map((y) => ({
	//           [t("name")]: y?.name,
	//           [t("orderNumber")]: y?.orderNumber,
	//           [t("commission")]: y?.commission,
	//           [t("price")]: y?.price,
	//           [t("createdAt")]: y?.createdAt,
	//         }));
	//       }
	//       console.log("data =====>", data);

	//       exportToCSV({ apiData: data, fileName: "allFinance", head, cols });
	//     })
	//     .catch((err) => {
	//       console.log({ err });
	//     });
	// };
	// // ---------------------------------------------- breadcrumbs
	// const breadcrumbs = [
	//   { title: "Dashboard", link: routes.dashboard.base },
	//   { title: COMPONENT_NAMES[0], link: newRoute.base },
	//   { title: "List" },
	// ];
	// // ---------------------------------------------- EFFECTS
	// useEffect(() => {
	//   dispatch(actionBreadcrumbs.set(breadcrumbs));
	// }, []);

	// // const handleShowModal = () => setShowModal(true)
	// // const handleHideModal = () => setShowModal(false)

	// const tableOptions = tableData({
	//   isBranch,
	//   isHotel,
	//   isAdmin,
	//   onClick: clickAction, newRoute, active: {
	//     // manuel: {
	//     //   icon: 'mdi mdi-source-branch',
	//     //   class: 'bg-success',
	//     //   label: "change branch",
	//     //   onClick: (data) => {
	//     //     setShowModal(data)
	//     //   }
	//     // }
	//   }
	// });

	// const headButtonData = [
	//   {
	//     label: "Total Progress Payment",
	//     value: data?.totalCommission && " ₺ " + changePriceFormat(data?.totalCommission, 2)
	//   },
	//   {
	//     label: "Total Paid",
	//     value: " ₺ " + changePriceFormat(data?.totalPaid && data?.totalPaid, 2)
	//   },
	//   {
	//     label: "Total Remaining",
	//     value: data?.totalPaid && " ₺ " + changePriceFormat(data?.totalCommission - data?.totalPaid, 2)
	//   },
	//   {
	//     label: "Most Recent Request Date",
	//     value: data?.nextPaymentDate && isoToDate(data?.nextPaymentDate)
	//   },
	// ]

	// const [showModal, setShowModal] = useState(false);
	// const [CheckModal, setCheckModal] = useState(false);

	// const handleCheckModal = () => {
	//   setCheckModal(!CheckModal);
	// };

	// const handleGalleryModal = () => {
	//   setShowModal(!showModal);
	// };

	// const button = invoicePermissions?.create || validPermission ? { title: "Request Payment", onClick: handleGalleryModal } : "";
	// //  ----------------------------------------- LOGS ----------------------------------------- //
	// console.log({ button, adminState, invoicePermissions }, "checkList");

	return (
		<div>
			{isAdmin ? (
				<div className="text-center">
					<ButtonGroup
						style={{
							width: "50%",
						}}
						aria-label="Basic example">
						{
							// handleChangeDate
							FINANCE_DATE?.map((x) => (
								<Button
									style={{
										flex: 1,
									}}
									onClick={() => {
										navigate({
											pathname: routes.financeHotels.base + routes.financeHotels.list + "/" + x,
										});
									}}
									variant={years == x ? "primary" : "secondary"}>
									{x}
								</Button>
							))
						}
					</ButtonGroup>
				</div>
			) : (
				""
			)}

			{years == "2023" ? <Years2023 /> : <Years2024 />}
		</div>
	);
};

export default List;
