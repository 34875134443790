import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import { ROLES, STATUSES } from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import CheckBox from "components/CheckBox";
import FieldDate from "components/FieldDate";
import moment from "moment";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";
import { useTranslation } from "react-i18next";

const Add = () => {
	const [showPass, setShowPass] = useState(false);
	const [showConfirmPass, setShowConfirmPass] = useState(false);
	const [type, setType] = useState(null);
	const { t } = useTranslation();
	const location = useLocation();
	const navigation = useNavigate();

	// ------------------------------------ route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const previousQuery = location?.state?.query;

	const typeOptions = [
		{ label: "User", value: "USER" },
		{ label: "Admin", value: "ADMIN" },
	];

	let { id } = useParams();
	const URL_ID = id ? id : "";

	// ----------------------------------------------------------------------------- Fetching Functions
	const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
	const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
	const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);
	// const getRoles = async () => await axiosClient().get(api["role"].list);

	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(validation(URL_ID)),
		mode: "onSubmit",
	});

	// ----------------------------------------------------------------------------- Mutation
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		const params = new URLSearchParams(previousQuery || {});
		navigation(newRoute.base + newRoute.list + `?${params.toString()}`);
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: URL_ID ? updating : creating,
		invalidQuery: `getById_${API_NAME}`,
		onSuccess: onSuccessMutating,
	});

	// ----------------------------------------------------------------------------- Query By ID
	const onSuccessDataById = ({ data }) => {
		let { systemStartTime, systemEndTime,vigoDelayInMinutes, ...params } = { ...data };
		let selectedStatus = STATUSES.find((item) => item.value === params.isActive);
		// moment("1946-05-21").set("hour", hours).set("minute", minutes);
		// const now=moment(new Date()).format('hh:mm A')
		let date = "2017-03-13";

		reset({
			...params,
			systemStartTime: new Date(moment(date + " " + systemStartTime).format()),
			systemEndTime: new Date(moment(date + " " + systemEndTime).format()),
			vigoDelayInMinutes: +(vigoDelayInMinutes || 0),

		});
	};
	const dataById = useQueryCustom({
		name: `getById_${API_NAME}`,
		url: getById,
		onSuccess: onSuccessDataById,
		enabled: !!URL_ID,
	});

	//  ----------------------------------------- GETTING ROLES ----------------------------------------- //
	// const onSuccessRoles = ({ data }) => {
	//   setRolesOption(convertToLabelValue(data?.result));
	// };
	// const rolesData = useQueryCustom({
	//   name: "rolesData",
	//   url: getRoles,
	//   onSuccess: onSuccessRoles,
	//   params: { sort: "desc", page: 1, limit: 10, status: 1 },
	// });
	// --------------------------------------- Submit Form
	const onSubmit = () => {
		const { systemStartTime, systemEndTime, ...values } = getValues();
		const requestData = {
			...values,
			isSystemWorking: !!values.isSystemWorking,
			systemStartTime: moment(systemStartTime).locale("en").format("hh:mm A"),
			systemEndTime: moment(systemEndTime).locale("en").format("hh:mm A"),
		};

		console.log({ requestData, values });
		mutate(requestData);
	};

	// ----------------------------------------------------------------------------- Constance
	const dispatch = useDispatch();
	const button = {
		title: `Back To ${COMPONENT_NAMES[0]} List`,
		link: newRoute.base + newRoute.list,
	};

	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: COMPONENT_NAMES[0], link: newRoute.base },
		{
			title: URL_ID ? `Edit / ${dataById?.data?.data?.result?.name} ${dataById?.data?.data?.result?.name}` : "Add",
		},
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, [dataById]);
	// ----------------------------------------------------------------------------- Log
	const values = getValues();
	console.log({ dataById, values }, "Add");
	console.log({ URL_ID });
	console.log({ errors }, "errors");

	// ----------------------------------------------------------------------------- UseEffects

	//  ----------------------------------------- GALLERY ----------------------------------------- //
	const [showModal, setShowModal] = useState(false);
	const [thumbnail, setThumbnail] = useState(null);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};
	const handleSelectThumbnail = (thumbnail) => {
		setValue(fieldNames.image, thumbnail, {
			shouldValidate: true,
		});
		setThumbnail(thumbnail);
	};

	// const dateStartState = watch(fieldNames.systemStartTime)
	// const dateEndState = watch(fieldNames.systemEndTime)

	// console.log({ dateStartState, dateEndState }, moment(dateStartState).format('hh:mm:ss A'));
	return (
		<div>
			<GalleryModal
				{...{
					showModal,
					handleModal: handleGalleryModal,
					handleClickedImage: handleSelectThumbnail,
					selectedPhoto: thumbnail,
				}}
			/>
			<PageTitle title={URL_ID ? `Edit  ${COMPONENT_NAMES[0]}` : `Add  ${COMPONENT_NAMES[0]}`} button={button} />
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				<div className="row mt-5">
					<Col md="8">
						<div className="col-12 border p-4">
							<div className="">
								<div className="b-1">
									<h4 className="">{"Basic Information"}</h4>
								</div>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form">
											<FieldDate
												require
												{...{
													control,
													name: fieldNames.systemStartTime,
													register,
													placeholder: " 11:20 am",
													label: "systemStartTime",
													errors,
												}}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={5}
												timeCaption="Time"
												dateFormat="HH:mm"
												showTimeInput={false}
											/>

											<FieldDate
												require
												{...{
													control,
													name: fieldNames.systemEndTime,
													register,
													placeholder: "systemEndTime",
													label: "systemEndTime",
													errors,
												}}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={5}
												timeCaption="Time"
												dateFormat="HH:mm"
												showTimeInput={false}
											/>

											<FieldText
												require
												{...{
													name: fieldNames.currentNotWorkingReason,
													register,
													placeholder: "currentNotWorkingReason",
													label: "currentNotWorkingReason",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.vigoToken,
													register,
													placeholder: "vigoToken",
													label: "vigoToken ",
													errors,
												}}
											/>


<FieldText
												require
												noDecimal
												{...{
													name: fieldNames.vigoDelayInMinutes,
													register,
													placeholder: "vigoDelayInMinutes",
													label: "vigoDelayInMinutes",
													errors,
													type: "number",
												}}
											/>

											<CheckBox
												{...{
													name: fieldNames.isSystemWorking,
													register,
													label: "isSystemWorking",
													errors,
													control,
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>

					<Col md="4">
						<div className="row">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Actions"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											{/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

											{/* <hr /> */}

											<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
												{!isLoading ? t("publish") : "Loading..."}
											</Button>
										</div>
									</div>
								</div>
							</Col>
						</div>
					</Col>
				</div>
			</form>
		</div>
	);
};

export default Add;
