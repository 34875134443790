import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
	systemStartTime: "systemStartTime",
	systemEndTime: "systemEndTime",
	currentNotWorkingReason: "currentNotWorkingReason",
	isSystemWorking: "isSystemWorking",
	vigoToken: "vigoToken",
	vigoDelayInMinutes: "vigoDelayInMinutes",

};

export const validation = (URL_ID) =>
	yup.object().shape({
		[fieldNames.systemStartTime]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.systemStartTime)).label(fieldNames.systemStartTime),
		[fieldNames.systemEndTime]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.systemStartTime)).label(fieldNames.systemEndTime),
		[fieldNames.vigoDelayInMinutes]: yup
		.number()
		.transform((value, originalValue) => (originalValue === "" ? null : value))
		.min(1, ERRORS.min(1))
		.nullable()
		.label(fieldNames.systemEndTime),
	});
