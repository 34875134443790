import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { ORDER_STATUS_DATA, STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PERMISSION } from "../enum";
import { tableData } from "./tableInfo";
import Factor from "containers/Factor";
// import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import isoToDate from "utils/isoToDate";

import { Button, Col, Modal, Row } from "react-bootstrap";
import { fieldNames, validation } from "./validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BranchModal from "./../../../components/BranchModal/index";
import secondsToHms from "utils/secondsToHms";
import ShowModal from "./component/OrderSetting/ShowModal";
// import ExcelJS from "exceljs";
// import isoToTime from "utils/isoToTime";
import moment from "moment";
import NoteForStatusCancel from "./component/NoteForStatusCancel";
import BoxWithLabelValue from "components/BoxWithLabelValue";

const List = () => {
	const [checkList, setCheckList] = useState();
	const [showModal, setShowModal] = useState(false);
	const [isLoadingExcel, setIsLoadingExcel] = useState(false);
	const [modalStatusCancel, setModalStatusCancel] = useState({
		show: false,
	});
	const [showInfoModal, setShowInfoModal] = useState(false);
	const [show, setShow] = useState(false);
	const [currentEditingOrder, setCurrentEditingOrder] = useState("");
	const adminState = useSelector((state) => state.admin);
	const validPermission = adminState?.data?.permissions === true;
	let permissions = validPermission || adminState?.data?.permissions?.[PERMISSION];
	const statisticsPermissions = validPermission || adminState?.data?.permissions?.["statistics"];
	const isBranch = adminState.data.isBranch;
	const isHotel = adminState.data.isHotel;
	const isAdmin = adminState.data.isAdmin;
	const isNetworkDry = adminState.data?.isNetworkDry;
	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	// ---------------------------------------------- Constance
	// const button = { title: `Add ${COMPONENT_NAMES[0]}`, link: newRoute.base + newRoute.add };
	const QueryString = useQueryString({ sort: "desc" });
	const { page, limit } = QueryString;
	const dispatch = useDispatch();

	// ---------------------------------------------- fetching functions
	const getting = async () => await axiosClient().get(api[API_NAME].list, { params: { ...QueryString } });

	const gettingStatusStatistic = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].statistics.status, {
			params: { from, to },
		});
	};

	const gettingStatistics = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].statistics.v1, {
			params: { from, to },
		});
	};

	const gettingStatisticHotels = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].statistics.hotel, {
			params: { from, to },
		});
	};

	const gettingStatisticsV2 = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].statistics.v2, {
			params: { from, to },
		});
	};

	const gettingStatisticRooms = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].statistics.room, {
			params: { from, to },
		});
	};

	const gettingOrders = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].products, {
			params: { from, to },
		});
	};
	const DeliveryReport = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].deliveryReport, {
			params: { from, to },
		});
	};

	const changeStatus = async (params) => {
		console.log(params, "changeStatus");
		const data = {
			paymentType: params?.data?.paymentType,
			delivery: params?.delivery,
			status: params.status,
			...(params.note && { cancelNote: params.note }),
		};
		return await axiosClient().patch(api[API_NAME].base + "/" + params?.data?.id, data);
		// await axiosClient().patch(api[API_NAME].changeStatus, params);
	};
	const unassign = async (params) => {
		console.log({ params }, "params");

		return await axiosClient().patch("/v1/admin/orders/unassign-delivery/" + params?.data?.id);
		// await axiosClient().patch(api[API_NAME].changeStatus, params);
	};

	// ------------------------------------------------------- query Get
	const resStatusStatistic = useQueryCustom({
		name: `${API_NAME}_status-statistic_get`,
		url: gettingStatusStatistic,
		enabled: !!isAdmin,
		params: { from: QueryString.from, to: QueryString.to },
	});
	console.log({ resStatusStatistic });
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get`,
		url: getting,
		params: { ...QueryString },
		enabled: !!(validPermission || permissions?.list),
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessChangeStatusMutation = () => {
		toastify.success({ title: "success" });
		if (isAdmin) resStatusStatistic.refetch();
	};

	const changeStatusMutation = useMutationCustom({
		name: `${API_NAME}_changeStatus`,
		url: changeStatus,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessChangeStatusMutation,
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessUnassignMutation = () => {
		toastify.success({ title: "success" });
	};

	const unassignMutation = useMutationCustom({
		name: `${API_NAME}_Unassign`,
		url: unassign,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessUnassignMutation,
	});
	// ---------------------------------------------- PRINT

	// const handlePrint = ({ params }) => useReactToPrint({
	//   content: <Factor {...{ params }} />,
	//   // documentTitle: "AwesomeFileName",
	//   // onBeforeGetContent: handleOnBeforeGetContent,
	//   // onBeforePrint: handleBeforePrint,
	//   // onAfterPrint: handleAfterPrint,
	//   removeAfterPrint: true
	// });
	// ---------------------------------------------- logs
	console.log({ data, error, isError, isLoading });
	// ---------------------------------------------- action click table

	const modalStyles = {
		position: "fixed",
		top: "50%",
		left: "50%",
		transform: `translate(-50%, -50%)`,
		backgroundColor: "white",
		width: "300px",
		height: "300px",
		borderRadius: "5px",
		padding: "20px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	};
	const actionsOperation = ({ data, status, note }) => {
		console.log("action statu ===>", { data, status, note });
		// if (status.value === "on_way") {
		//   setShowModal(true);
		//   setCurrentEditingOrder(data.id);
		// if (status.value === object.canceled) {
		//   setShowModal(true);
		// setCurrentEditingOrder(data.id);
		// } else {
		setShowModal(false);
		setCurrentEditingOrder("");
		swal({
			title: "Are you sure?",
			text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				changeStatusMutation.mutate({ data, status: status.value, note });
				if (status.value === "payment_success") {
					console.log("triggred");
				}
			}
		});
		// }
	};

	const onWayStatusAction = () => {
		const { delivery } = getValues();
		const requestData = {
			status: "on_way",
			delivery: delivery.value,
			data: {
				id: currentEditingOrder,
			},
		};
		console.log("requestData", { requestData });
		changeStatusMutation.mutate(requestData);
		setShowModal(false);
	};

	const handleUnassign = (data) => {
		console.log({ data });
		swal({
			title: "Are you sure?",
			text: `You want unassign this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				unassignMutation.mutate({ data });
				// if (status.value === "payment_success") {
				//   console.log("triggred");
				// }
			}
		});
	};

	const clickAction = ({ data, status, key }) => {
		// if (status.label === "print") return handlePrint(data)
		// const newData = data.map((x) => x.id);
		if (status?.value === "canceled") {
			setModalStatusCancel({ data, status: status, show: true });
		} else if (key == "unassign") handleUnassign(data);
		else actionsOperation({ data: data, status });
	};

	const handleAction = (status) => {
		const data = Object.keys(checkList);
		const { object } = ORDER_STATUS_DATA(isBranch, isHotel);

		// console.log({ data, status });
		actionsOperation({ data, status: status });
	};

	const exportToCSV = ({ apiData, fileName, head, cols }) => {
		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e["c"] = head.length - 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);
		// worksheet["!autofilter"] = { ref: "A1:E1" };
		// worksheet["!filter"] = `A1:${String.fromCharCode(65 + head.length - 1)}1`;
		worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		// worksheet["!freeze"] = { xSplit: '1', ySplit: '0', topLeftCell: 'A2', activePane: 'bottomRight', state: 'frozen' };
		// console.log("********** aaaaaaaaaaaa:", `A1:${String.fromCharCode(65 + head.length - 1)}1`);
		worksheet["!cols"] = cols;

		XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
		XLSX.writeFile(workbook, (fileName || "excel_export") + "_" + adminState?.data?.name + "_" + adminState?.data?.lastName + ".xlsx");
	};

	// const rideDurationColIndex = head.indexOf(t("rideDuration"));

	// if (rideDurationColIndex !== -1) {
	//   const range = XLSX.utils.decode_range(worksheet['!ref']);
	//   for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
	//     const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: rideDurationColIndex });
	//     const cell = worksheet[cellAddress];
	//     if (cell) {
	//       const totalSeconds = cell.v;

	//       const date = new Date(null);
	//       date.setSeconds(totalSeconds);
	//       console.log({ date });
	//       cell.v = '2024-01-03T00:01:00.267Z';
	//       cell.t = 'd';
	//       cell.z = 'mm:ss';
	//     }
	//   }
	// }

	// const exportToCSV2 = async ({ apiData, fileName, head, cols }) => {
	//   const workbook = new ExcelJS.Workbook();
	//   const worksheet = workbook.addWorksheet('tab1');

	//   // Add header row
	//   worksheet.addRow(head);

	//   // Add data rows
	//   apiData.forEach((rowData) => {
	//     const newRow = [];
	//     head.forEach((key) => {
	//       // if (key === 'rideDuration') {
	//       //   const totalSeconds = rowData[key];
	//       //   const minutes = Math.floor(totalSeconds / 60);
	//       //   const seconds = totalSeconds % 60;
	//       //   const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
	//       //   newRow.push(formattedTime);
	//       // } else {
	//       // }
	//       newRow.push(rowData[key]);
	//     });
	//     const row = worksheet.addRow(newRow);

	//     // Set data type of the 'rideDuration' (or 'time') column to 'time'
	//     row.eachCell((cell, colNumber) => {
	//       if (head[colNumber - 1] === 'rideDuration') {
	//         cell.numFmt = 'hh:mm'; // Set the cell's number format to 'time'
	//       }
	//     });
	//   });

	//   // Set column width
	//   cols.forEach((col, index) => {
	//     worksheet.getColumn(index + 1).width = col.width;
	//   });

	//   // Save workbook to file
	//   workbook.xlsx.writeBuffer().then((buffer) => {
	//     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

	//     const link = document.createElement('a');
	//     link.href = URL.createObjectURL(blob);
	//     link.download = (fileName || 'excel_export') + '.xlsx';
	//     document.body.appendChild(link);
	//     link.click();
	//     document.body.removeChild(link);
	//   });
	// };

	//
	// const createdAtColIndex = head.indexOf(t("createdAt"));
	// console.log({ createdAtColIndex }, head, t("createdAt"));
	// if (createdAtColIndex !== -1) {
	//   const range = XLSX.utils.decode_range(worksheet['!ref']);
	//   for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
	//     const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: createdAtColIndex });
	//     const cell = worksheet[cellAddress];
	//     console.log({ cell });
	//     if (cell?.v) {
	//       cell.t = 'd'
	//       cell.z = 'MM/DD/YYYY HH:MM:SS';
	//     }
	//   }
	// }

	const handleGetFullDataWithDate = async ({ callbackData } = {}) => {
		let cols = [
			{ wch: 30 },
			{ wch: 15 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
		];

		return await axiosClient()
			.get(api[API_NAME].fullData, {
				params: { ...QueryString, limit: 0, page: 1 },
			})
			.then((x) => {
				// console.log({ x });
				const head = [
					t("name"),
					t("orderNumber"),
					t("roomNumber"),
					t("paymentType"),
					t("totalScan"),
					t("vigoDelivery"),
					t("status"),
					t("vigoDelivery"),
					t("totalPrice"),
					t("hotelName"),
					t("region"),
					t("date"),
					t("clock"),
				];
				const data = x?.data?.map((y) => {
					const date = moment(y?.createdAt);
					// if (y?.vigoDelivery?.assignedCourier) {
					// 	alert();
					// 	console.log("* * * has vigodelivery", { y });
					// }
					// console.log("* * * noting vigodelivery", { y });
					return {
						[t("name")]: y?.name,
						[t("orderNumber")]: y?.orderNumber,
						[t("roomNumber")]: y?.roomNumber,
						[t("paymentType")]: y?.paymentType,
						[t("totalScan")]: y?.totalScan,
						[t("vigoDelivery")]: y?.vigoDelivery?.assignedCourier?.fullname,
						[t("status")]: y?.status,
						[t("vigoDelivery")]: y?.vigoDelivery?.assignedCourier?.fullname,
						[t("totalPrice")]: y?.totalPrice,
						[t("hotelName")]: y?.hotelName,
						[t("region")]: y?.region?.name,
						[t("date")]: date.format("YYYY-MM-DD"),
						[t("clock")]: date.format("HH:mm:ss"),
					};
				});
				console.log("data =====>", data);

				if (callbackData) return { data, head, cols, name: "allOrders" };
				else exportToCSV({ apiData: data, fileName: "allOrders", head, cols });
			})
			.catch((err) => {
				console.log({ err });
			});
	};

	const handleGetStatistics = () => {
		let cols = [{ wch: 30 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
		gettingStatistics()
			.then((x) => {
				console.log({ x });

				const head = [t("hotelName"), t("totalOrder"), t("totalScan")];
				const data = x?.data?.map((y) => ({
					[t("hotelName")]: y?.hotelName,
					[t("totalOrder")]: y?.totalOrder,
					[t("totalScan")]: y?.totalScan,
				}));
				exportToCSV({
					apiData: data,
					fileName: `statistic-from-${QueryString.from || "now"}-to-${QueryString.to || new Date().toISOString()}`,
					head,
					cols,
				});
			})
			.catch((err) => {
				console.log({ err });
			});
	};

	const handleGetStatisticRooms = async () => {
		try {
			const response = await gettingStatisticRooms();
			const data = response?.data;
			const wb = XLSX.utils.book_new();

			const headerRow = [t("hotel"), t("rooms"), "", ""];
			const subHeaderRow = ["", t("roomNumber"), t("quantity"), t("orderCount")];

			const rows = [headerRow, subHeaderRow];

			data.forEach((hotel) => {
				hotel.rooms.forEach((room, index) => {
					const roomRow = [index === 0 ? hotel.hotel : "", room.roomNumber, room.quantity, room.orderCount];
					rows.push(roomRow);
				});
			});

			const ws = XLSX.utils.aoa_to_sheet(rows);

			ws["!merges"] = [{ s: { r: 0, c: 1 }, e: { r: 0, c: 3 } }];
			ws["!cols"] = [{ wch: 30 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];

			ws["B1"].s = { alignment: { horizontal: "center" } };

			ws["!ref"] = XLSX.utils.encode_range({
				s: { r: 0, c: 0 },
				e: { r: rows.length - 1, c: 3 },
			});

			XLSX.utils.book_append_sheet(wb, ws, "Hotels");

			XLSX.writeFile(
				wb,
				`statistic-rooms-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}` +
					"_" +
					adminState?.data?.name +
					"_" +
					adminState?.data?.lastName +
					".xlsx"
			);
		} catch (error) {}
		// let cols = [{ wch: 30 }, { wch: 15 }, { wch: 15 }];
		// gettingStatisticRooms()
		// 	.then((x) => {
		// 		console.log({ x });

		// 		const workbook = XLSX.utils.book_new();

		// 		const head = [t("rooms-scans-orders"), t("scan-count"), t("orderCount")];
		// 		x?.data?.map((y) => {
		// 			let apiData = y?.rooms?.map((z) => ({
		// 				[t("rooms-scans-orders")]: z?.roomNumber,
		// 				[t("scan-count")]: z?.quantity,
		// 				[t("orderCount")]: z?.orderCount,
		// 			}));

		// 			const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });
		// 			const range = XLSX.utils.decode_range(worksheet["!ref"]);
		// 			range.e["c"] = head.length - 1;
		// 			worksheet["!ref"] = XLSX.utils.encode_range(range);
		// 			worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		// 			worksheet["!cols"] = cols;
		// 			let limitedString = y?.hotel?.length > 30 ? y?.hotel?.substring?.(0, 30) : y?.hotel;
		// 			XLSX.utils.book_append_sheet(workbook, worksheet, limitedString);
		// 		});

		// 		XLSX.writeFile(
		// 			workbook,
		// `statistic-rooms-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}` +
		// 	"_" +
		// 	adminState?.data?.name +
		// 	"_" +
		// 	adminState?.data?.lastName +
		// 	".xlsx"
		// 		);
		// 	})
		// 	.catch((err) => {
		// 		console.log({ err });
		// 	});
	};

	const handleGetStatisticHotels = async ({ callbackData } = {}) => {
		let cols = [{ wch: 30 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
		return await gettingStatisticHotels()
			.then((x) => {
				console.log({ x });

				const head = [t("hotelName"), t("orderCount"), t("scanCount"), t("regionName")];
				const data = x?.data?.map((y) => ({
					[t("hotelName")]: y?.hotelName,
					[t("orderCount")]: y?.orderCount,
					[t("scanCount")]: y?.scanCount,
					[t("regionName")]: y?.regionName,
				}));
				if (callbackData) return { data, head, cols, name: "statistic" };
				else
					exportToCSV({
						apiData: data,
						fileName: `statistic-from-${QueryString.from || "now"}-to-${QueryString.to || new Date().toISOString()}`,
						head,
						cols,
					});
			})
			.catch((err) => {
				console.log({ err });
			});
	};

	const handleGetStatisticsV2 = async ({ callbackData } = {}) => {
		let cols = [{ wch: 30 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
		return await gettingStatisticsV2()
			.then((x) => {
				console.log({ x });
				const head = [t("hotelName"), t("regionName"), t("roomCount"), t("type"), t("successful"), t("date"), t("clock")];
				const data = x?.data?.map((y) => {
					const date = moment(y?.createdAt);
					return {
						[t("hotelName")]: y?.hotelName,
						[t("regionName")]: y?.regionName,
						[t("roomCount")]: y?.roomNumber,
						[t("type")]: y?.source,
						[t("successful")]: String(y?.isOrderSubmitted),
						[t("date")]: date.format("YYYY-MM-DD"),
						[t("clock")]: date.format("HH:mm:ss"),
					};
				});
				if (callbackData) return { data, head, cols, name: "statistic-details" };
				else
					exportToCSV({
						apiData: data,
						fileName: `statistic-details-from-${QueryString.from || "now"}-to-${QueryString.to || new Date().toISOString()}`,
						head,
						cols,
					});
			})
			.catch((err) => {
				console.log({ err });
			});
	};
	//handleGetDetailedOrdersWithDate

	const handleGetDetailedOrdersWithDate = async ({ callbackData } = {}) => {
		let cols = [
			{ wch: 30 },
			{ wch: 15 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
		];
		return await gettingOrders()
			.then((x) => {
				console.log({ x });

				let head = [
					"id",
					t("id"),
					t("productId"),
					"bayi",
					"otel",
					"siparisNo",
					"durum",
					"odeme_tur",
					"adet",
					// "kdv",
					"fiyat",
					"alis_fiyat",
					// "teslim_yeri",
					"urun_adi",
					// "stock",
					"entegrasyon_kodu",
					// "alınan_yer",
					// t("productBranch"),
					t("date"),
					t("clock"),
					// t("productId"),
					// t("branch"),
					// t("hotelNames"),
					// t("orderNumber"),
					// t("status"),
					// t("paymentType"),
					// t("quantity"),
					// t("tax"),
					// t("buyPrice"),
					// // t("totalBuyPrice"),
					// t("totalPrice"),
					// t("productName"),
					// t("integrationCode"),
					// t("productBranch"),
					// t("date"),
					// t("clock"),
				];

				let data = [];

				if (isBranch) {
					// if (isNetworkDry) {
					// 	head = [t("productName"), t("orderNumber"), t("buyPrice"), t("hotelName"), t("roomNumber"), t("phoneNumber"), t("address")];

					// 	data = x?.data?.map((y) => ({
					// 		[t("productName")]: y?.productName,
					// 		[t("orderNumber")]: y?.orderNumber,
					// 		[t("buyPrice")]: y?.buyPrice,
					// 		[t("hotelName")]: y.hotel?.name,
					// 		[t("roomNumber")]: y?.roomNumber,
					// 		[t("address")]: y.hotel?.address,
					// 		[t("phoneNumber")]: y.hotel?.phoneNumber,
					// 	}));
					// } else {
					head = [t("productName"), t("orderNumber"), t("buyPrice")];

					data = x?.data?.map((y) => ({
						[t("productName")]: y?.productName,
						[t("orderNumber")]: y?.orderNumber,
						[t("buyPrice")]: y?.buyPrice,
					}));
					// }
				} else {
					data = x?.data?.map((y) => {
						const date = moment(y?.createdAt);
						return {
							// [t("productId")]: y?.id,
							// [t("branch")]: y?.branch.name,
							// [t("hotelNames")]: y?.hotelName,
							// [t("orderNumber")]: y?.orderNumber,
							// [t("status")]: y?.status,
							// [t("paymentType")]: y?.paymentType,
							// [t("quantity")]: y?.quantity,
							// [t("kdv")]: y?.kdv,
							// [t("totalPrice")]: y?.price,
							// [t("buyPrice")]: y?.buyPrice,
							// // totalBuyPrice: y?.buyPrice * y?.quantity,
							// [t("productName")]: y?.productName,
							// [t("integrationCode")]: y?.productIntegrationCode,
							// [t("date")]: date.format("YYYY-MM-DD"),
							// [t("clock")]: date.format("HH:mm:ss"),
							[t("id")]: y?.id,
							[t("productId")]: y?.productId,
							bayi: y?.branch?.name || y?.branch,
							otel: y?.hotelName || y.hotel?.name,
							siparisNo: y?.orderNumber,
							durum: y?.status,
							odeme_tur: y?.paymentType,
							adet: y?.quantity,
							// kdv: y?.kdv,
							fiyat: y?.price,
							alis_fiyat: y?.buyPrice,
							// teslim_yeri: y?.delivery,
							urun_adi: y?.productName,
							// stock: y?.productName,
							entegrasyon_kodu: y?.productIntegrationCode,
							// alınan_yer: y?.productPlace,
							// [t("productBranch")]: y?.productBranch || "-",
							[t("date")]: date.format("YYYY-MM-DD"),
							[t("clock")]: date.format("HH:mm:ss"),
						};
					});
				}

				if (callbackData) return { data, head, cols, name: "orders" };
				else
					exportToCSV({
						apiData: data,
						fileName: `orders-from-${QueryString.from || "now"}-to-${QueryString.to || new Date().toISOString()}`,
						head,
						cols,
					});
			})
			.catch((err) => {
				console.log({ err });
			});
	};

	const secondsToTimeFormat = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
	};

	const handleGetDeliveryReportWithDate = async ({ callbackData } = {}) => {
		return await DeliveryReport()
			.then((x) => {
				console.log({ x });

				const head = [
					t("deliveryName"),
					t("orderNumber"),
					t("regionName"),
					t("guestName"),
					t("hotelName"),
					t("roomNumber"),
					t("totalPrice"),
					t("totalPriceTry"),
					t("paymentType"),
					t("roomCount"),
					t("status"),
					t("date"),
					t("clock"),
					t("assignedAt"),
					t("onWayAt"),
					t("assignmentDuration"),
					t("rideDuration"),
					t("deliveryDuration"),
					t("deliveredAt"),
					t("productsBuyed"),
				];

				let cols = [
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
				];

				let calcAverage = {
					delivered: 0,
					assignmentDuration: 0,
					rideDuration: 0,
					deliveryDuration: 0,
				};

				const data = x?.data?.map((y) => {
					if (y?.status == "delivered") {
						calcAverage.delivered++;
						calcAverage.rideDuration += y?.rideDuration;
						calcAverage.assignmentDuration += y?.assignmentDuration;
						calcAverage.deliveryDuration += y?.deliveryDuration;
					}
					const date = moment(y?.createdAt);

					return {
						[t("deliveryName")]: y?.delivery || "-",
						[t("orderNumber")]: y?.orderNumber || "-",
						[t("guestName")]: y?.name || "-",
						[t("hotelName")]: y?.hotel?.name || "-",
						[t("roomNumber")]: y?.roomNumber || "-",
						[t("totalPrice")]: y?.totalPrice || "-",
						[t("totalPriceTry")]: y?.totalPriceTry || "-",
						[t("paymentType")]: y?.paymentType || "-",
						[t("status")]: y?.status || "-",
						[t("regionName")]: y?.region?.name || "-",
						[t("roomCount")]: y?.hotel?.roomCount || "-",
						[t("date")]: date.format("YYYY-MM-DD"),
						[t("clock")]: date.format("HH:mm:ss"),
						// [t("createdAt")]: y?.createdAt ? isoToDate(y.createdAt) : "-",
						[t("assignedAt")]: y?.assignedAt ? isoToDate(y?.assignedAt) : "-",
						[t("onWayAt")]: y?.onWayAt ? isoToDate(y?.onWayAt) : "-",
						[t("assignmentDuration")]: y?.assignmentDuration ? secondsToHms(y?.assignmentDuration) : "-",
						[t("rideDuration")]: y?.rideDuration ? secondsToHms(y?.rideDuration) : "-",
						[t("deliveryDuration")]: y?.deliveryDuration ? secondsToHms(y?.deliveryDuration) : "-",
						[t("deliveredAt")]: y?.deliveredAt ? isoToDate(y?.deliveredAt) : "-",
						[t("productsBuyed")]: y?.products?.map((z) => z?.name)?.join(","),
					};
				});

				data.push({
					[t("deliveryName")]: "",
					[t("orderNumber")]: "",
					[t("guestName")]: "",
					[t("hotelName")]: "",
					[t("roomNumber")]: "",
					[t("totalPrice")]: "",
					[t("totalPriceTry")]: "",
					[t("paymentType")]: "",
					[t("status")]: "",
					[t("regionName")]: "",
					[t("roomCount")]: "",
					[t("date")]: "",
					[t("clock")]: "",
					[t("assignedAt")]: "",
					[t("onWayAt")]: "",
					[t("assignmentDuration")]: secondsToHms(calcAverage.assignmentDuration / calcAverage.delivered),
					[t("rideDuration")]: secondsToHms(calcAverage.rideDuration / calcAverage.delivered),
					[t("deliveryDuration")]: secondsToHms(calcAverage.deliveryDuration / calcAverage.delivered),
					[t("deliveredAt")]: "",
					[t("productsBuyed")]: "",
				});
				console.log("* * * handleGetDeliveryReportWithDate : ", {
					data,
					calcAverage,
				});

				if (callbackData) return { data, head, cols, name: "deliveryReports" };
				else
					exportToCSV({
						apiData: data,
						fileName: `deliveryReports-from-${QueryString.from || "now"}-to-${QueryString.to || new Date().toISOString()}`,
						head,
						cols,
					});
			})
			.catch((err) => {
				console.log({ err });
			});
	};
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [{ title: "Dashboard", link: routes.dashboard.base }, { title: COMPONENT_NAMES[0], link: newRoute.base }, { title: "List" }];
	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
	} = useForm({
		resolver: yupResolver(validation()),
		mode: "onSubmit",
	});
	// ---------------------------------------------- EFFECTS

	const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
		refetch();

		// const data = [
		//   {
		//     otel_uid: "0",
		//     unvan: "0",
		//     toplam_ziyaret_sayisi: "0",
		//     toplam_siparis_adet: "0",
		//     katalog_ziyaret_adedi: "0",
		//     katalog_siparis_adedi: "0",
		//   },
		//   {
		//     otel_uid: "2",
		//     unvan: "3",
		//     toplam_ziyaret_sayisi: "3",
		//     toplam_siparis_adet: "0",
		//     katalog_ziyaret_adedi: "0",
		//     katalog_siparis_adedi: "0",
		//   },
		// ];

		// exportToCSV({ apiData: data, fileName: "aa", head })

		// const fileName = 'download'
		// const exportType = 'xls'   //exported type could be text, json, csv, xls, xml
		// exportFromJSON({ data, fileName, exportType ,})
	}, []);
	// permissions.edit = true;
	const { t } = useTranslation();
	// adminState.permissions
	const tableOptions = tableData({
		onClick: clickAction,
		newRoute,
		isBranch,
		isHotel,
		isAdmin,
		isNetworkDry,
		// disable: { delete: true, edit: !isAdmin },
		disable: {
			delete: true,
			...(!validPermission && { edit: !permissions?.edit, unassignDelivery: !permissions?.unassignDelivery, products: !permissions?.delete }), //  ||!isBranch
			...(isBranch && { edit: true }),
		},
		active: {
			print: true,
			Component: Factor,
			...(isAdmin &&
				{
					// manuel: {
					//   icon: "mdi mdi-source-branch",
					//   class: "bg-success",
					//   label: "change branch",
					//   onClick: (data) => {
					//     setShow(data);
					//   },
					// },
				}),
			manuel: {
				icon: "mdi mdi-settings",
				class: "bg-danger",
				onClick: (data) => setShowInfoModal(data),
			},
		},
	});

	const headButtonData = useMemo(() => {
		const params = {
			delivered: {
				label: t("delivered"),
				value: 0,
			},
			canceled: {
				label: t("canceled"),
				value: 0,
			},
			duplicated: {
				label: t("duplicated"),
				value: 0,
			},
			served: {
				label: t("served"),
				value: 0,
			},
		};

		resStatusStatistic?.data?.data
			?.filter((x) => ["delivered", "canceled", "duplicated", "served"].includes(x?.status))
			?.map((x) => {
				params[x.status] = params[x.status] || { label: t(x.status) };
				params[x.status].value = x.total;
			});
		return Object.values(params);
	}, [resStatusStatistic]);

	//  ----------------------------------------- LOGS ----------------------------------------- //
	console.log({ checkList, adminState, data, resStatusStatistic }, "checkList");

	const handleGetFullExcel = async () => {
		const workbook = XLSX.utils.book_new();

		const callback = [handleGetDeliveryReportWithDate, handleGetDetailedOrdersWithDate, handleGetStatisticHotels, handleGetFullDataWithDate];

		for (let i = 0; i < callback.length; i++) {
			const curr = callback[i];

			const result = await curr({ callbackData: true });
			console.log("* * * handleGetFullExcel :", { result });
			let apiData = result?.data;
			const head = result?.head;
			const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });
			const range = XLSX.utils.decode_range(worksheet["!ref"]);
			range.e["c"] = head.length - 1;
			worksheet["!ref"] = XLSX.utils.encode_range(range);
			worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
			worksheet["!cols"] = result?.cols;
			// let limitedString = y?.hotel?.length > 30 ? y?.hotel?.substring?.(0, 30) : y?.hotel;
			XLSX.utils.book_append_sheet(workbook, worksheet, result?.name);
		}

		// x?.data?.map((y) => {
		// 	let apiData = y?.rooms?.map((z) => ({
		// 		[t("rooms-scans-orders")]: z?.roomNumber,
		// 		[t("scan-count")]: z?.quantity,
		// 		[t("orderCount")]: z?.orderCount,
		// 	}));
		// 	const head = [t("rooms-scans-orders"), t("scan-count"), t("orderCount")];
		// 	const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });
		// 	const range = XLSX.utils.decode_range(worksheet["!ref"]);
		// 	range.e["c"] = head.length - 1;
		// 	worksheet["!ref"] = XLSX.utils.encode_range(range);
		// 	worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		// 	worksheet["!cols"] = cols;
		// 	let limitedString = y?.hotel?.length > 30 ? y?.hotel?.substring?.(0, 30) : y?.hotel;
		// 	XLSX.utils.book_append_sheet(workbook, worksheet, limitedString);
		// });

		XLSX.writeFile(
			workbook,
			`full-statistic-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}` +
				"_" +
				adminState?.data?.name +
				"_" +
				adminState?.data?.lastName +
				".xlsx"
		);
	};
	const exceldata = {
		handleGetStatisticHotels,
		handleGetStatisticRooms,
		handleGetStatisticsV2,
		handleGetFullDataWithDate,
		handleGetDetailedOrdersWithDate,
		handleGetDeliveryReportWithDate,
		handleGetFullExcel,
	};

	const excelFunc = async (name) => {
		try {
			setIsLoadingExcel(true);
			await exceldata[name]();
			setIsLoadingExcel(false);
		} catch (error) {
			setIsLoadingExcel(false);
		}
	};
	return (
		<div>
			<ShowModal
				{...{
					setModal: setShowInfoModal,
					show: showInfoModal,
					isBranch,
					isHotel,
					isAdmin,
					onClick: clickAction,
					permissions,
					validPermission,
				}}
			/>
			<NoteForStatusCancel
				{...{
					setModal: (x) => setModalStatusCancel((p) => ({ ...p, show: x })),
					show: modalStatusCancel?.show,
					onSubmit: (x) => actionsOperation({ ...modalStatusCancel, note: x }),
				}}
			/>

			{/* <Modal
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ zIndex: 9999999999, maxWidth: 500, margin: "auto" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sipariş Sahibi Kurye</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(onWayStatusAction)}
            id={"form-container "}
            noValidate
          >
            <DeliveriesSelector
              {...{
                name: fieldNames.delivery,
                register,
                label: "delivery",
                errors,
                control,
              }}
            />
            <Button
              block
              type="success"
              htmlType="submit"
              className={`btn btn-success btn-sm`}
            >
              {t("publish")}
            </Button>
          </form>
        </Modal.Body>
      </Modal> */}
			{/* delivered, canceled, duplicated, served */}
			<PageTitle title={t("listsAuthor", { author: t(COMPONENT_NAMES[0]) })} breadcrumbs={breadcrumbs} />

			{isAdmin ? (
				<div>
					<Row className="my-5 ">
						{headButtonData?.map((x) => {
							return (
								<Col xs={3}>
									<BoxWithLabelValue small {...x} isLoading={isLoading} />
								</Col>
							);
						})}
					</Row>
				</div>
			) : (
				""
			)}
			<TableComponent
				{...{
					isHotel,
					QueryString,
					data: data?.result,
					tableOptions,
					loading: isLoading,
					search: {
						placeholder: t("search"),

						defaultValue: QueryString.search_text,
					},
					title: `${COMPONENT_NAMES[1]}`,
					// button: button,
					count: data.total,
					pagination: {
						activePage: page,
						pages: Math.ceil(data.total / limit),
						limited: 2,
					},
					checkbox: {
						state: checkList,
						setState: setCheckList,
					},
					actions: {
						onClick: handleAction,
					},
					status: STATUSES,
					dating:
						//  isAdmin
						//   ?
						{
							isLoading: isLoadingExcel,
							buttons: [
								// ...(validPermission || permissions?.statistics
								// 	? [
								// 			{
								// 				title: t("statics"),
								// 				onClick: handleGetStatistics,
								// 			},
								// 	  ]
								// 	: []),
								...(validPermission || permissions?.statistics
									? [
											{
												title: t("static-hotels"),
												onClick: () => excelFunc("handleGetStatisticHotels"),
											},
									  ]
									: []),
								...(validPermission || permissions?.statistics
									? [
											{
												title: t("static-rooms"),
												onClick: () => excelFunc("handleGetStatisticRooms"),
											},
									  ]
									: []),
								...(validPermission || permissions?.statistics
									? [
											{
												title: t("staticsDetailed"),
												onClick: () => excelFunc("handleGetStatisticsV2"),
											},
									  ]
									: []),
								...(validPermission || statisticsPermissions?.list
									? [
											{
												title: t("orders"),
												onClick: () => excelFunc("handleGetFullDataWithDate"),
											},
									  ]
									: []),
								...(validPermission || permissions?.products
									? [
											{
												title: t("detailedOrders"),
												onClick: () => excelFunc("handleGetDetailedOrdersWithDate"),
											},
									  ]
									: []),
								...(validPermission || permissions?.deliveryStatistics
									? [
											{
												title: t("deliveryReport"),
												onClick: () => excelFunc("handleGetDeliveryReportWithDate"),
											},
									  ]
									: []),
								...(validPermission || permissions?.deliveryStatistics
									? [
											{
												title: t("full"),
												onClick: handleGetFullExcel,
												onClick: () => excelFunc("handleGetFullExcel"),
											},
									  ]
									: []),
							],
							// button: {
							//   onClick: handleGetStatistics,
							// },
							// buttonFull: {
							//   onClick: handleGetFullDataWithDate,
							// }
						},
					// : true,
				}}
			/>

			<BranchModal {...{ show, setShow, refetchKey: `${API_NAME}_get` }} />
		</div>
	);
};

export default List;
