import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import { useMutationCustom } from "utils/useMutationCustom";
import { API_NAME } from "containers/Order/enum";
import swal from "sweetalert";
import Loading from "components/Loading";

const StruartStatus = ({ param, isAdmin, onClick }) => {
	const iscancel = param?.vigoStatus === "package_canceled";

	// ---------------------------------------------- mutation send-to-telegram
	const sendToTelegram = async () => {
		return await axiosClient().patch("/v1/admin/orders/send-to-telegram", { id: param?.id });
	};
	const onSuccessSendToTelegramMutation = () => {
		toastify.success({ title: "success" });
	};
	const sendToTelegramMutation = useMutationCustom({
		name: `${"send-to-telegram"}_sendToTelegram`,
		url: sendToTelegram,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessSendToTelegramMutation,
	});
	const handleSendToTelegram = () => {
		swal({
			title: "Are you sure?",
			text: `You want to send to telegram`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				sendToTelegramMutation.mutate({ data: [param], status: { value: "telegram" } });
			}
		});
	};
	// ----------------------------------------------  mutation retry-Vigo
	const retryVigo = async () => {
		return await axiosClient().patch("/v1/admin/orders/retry-vigo", { id: param?.id });
	};
	const onSuccessRetryVigoMutation = () => {
		toastify.success({ title: "success" });
	};
	const retryVigoMutation = useMutationCustom({
		name: `${"send-to-telegram"}_retryVigo`,
		url: retryVigo,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessRetryVigoMutation,
	});
	const handleRetryVigo = () => {
		swal({
			title: "Are you sure?",
			text: `Do you want to retry Vigo?`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				retryVigoMutation.mutate({ data: [param], status: { value: "telegram" } });
			}
		});
	};

	return (
		<div className="d-flex flex-column">
			<p>{param?.vigoStatus || "-"}</p>

			{isAdmin && iscancel ? (
				<div className="">
					{/* <button
						disabled={retryVigoMutation?.isLoading || sendToTelegramMutation?.isLoading}
						title="Send to telegram"
						className="btn btn-primary shadow btn-xs sharp pointer action-btn mdi rounded p-2 height-fit-content "
						onClick={handleSendToTelegram}>
						{sendToTelegramMutation?.isLoading ? <Loading size={"sm"} /> : <i className="mdi mdi-telegram" />}
					</button> */}
					<button
						disabled={retryVigoMutation?.isLoading || sendToTelegramMutation?.isLoading}
						title="Retry Vigo"
						className="btn btn-danger shadow btn-xs sharp pointer action-btn mdi rounded p-2 height-fit-content "
						onClick={handleRetryVigo}>
						{retryVigoMutation?.isLoading ? <Loading size={"sm"} /> : <i className="mdi mdi-undo-variant" />}
					</button>
					{param?.vigoCancelationReason ? <p className="mt-2 alert alert-warning">{param?.vigoCancelationReason}</p> : ""}
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default StruartStatus;
