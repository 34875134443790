import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import { tableData } from "./tableInfo";
import * as hotelEnum from "containers/Hotel/enum";
import * as financeEnum from "containers/Finance/enum";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import isoToDate from "utils/isoToDate";
import Finance from "../../Finance/index";
import moment from "moment";

const List = () => {
	const { t } = useTranslation();

	const [checkList, setCheckList] = useState();
	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const newHotelRoute = useMemo(() => getDataInObjectDepth(hotelEnum.ROUTE_NAME, routes), []);
	// ---------------------------------------------- Constance

	const dispatch = useDispatch();
	const location = useLocation();
	let { id, years } = useParams();
	let defaultYears = financeEnum.FINANCE_DATE[financeEnum.FINANCE_DATE.length - 1];
	years = years || defaultYears;
	const previousQuery = location?.state?.query;
	const previousRoute = location?.state?.route;
	const previousQueryParams = new URLSearchParams(previousQuery || {});
	console.log({ location, previousRoute });
	let minDate = new Date(years + "-01-01");
	let maxDate = new Date(years + "-12-31");
	const QueryString = useQueryString({ sort: "desc", from: minDate, to: maxDate });
	const { page, limit } = QueryString;

	// ---------------------------------------------- fetching functions
	const getting = async () =>
		await axiosClient().get(api[API_NAME].show + "/" + id, {
			params: { ...QueryString },
		});
	const changeStatus = async (params) => {
		console.log(params, "changeStatus");
		await axiosClient().patch(api[API_NAME].changeStatus, params);
	};
	const deleting = async (params) => {
		await axiosClient().delete(api[API_NAME].delete + "/" + params?.id);
	};
	const getById = async () => await axiosClient().get(api[hotelEnum.API_NAME].get + "/" + id);

	// ----------------------------------------------------------------------------- Mutation
	const dataById = useQueryCustom({
		name: `getById_${hotelEnum.API_NAME}_${id}`,
		url: getById,
		// onSuccess: onSuccessDataById,
		enabled: !!id,
	});
	console.log({ dataById });
	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get_${id}`,
		url: getting,
		params: { ...QueryString },
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessDeleteMutation = () => {
		toastify.success({ title: "success" });
	};

	const deleteMutation = useMutationCustom({
		name: `${API_NAME}_delete`,
		url: deleting,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessDeleteMutation,
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessChangeStatusMutation = () => {
		toastify.success({ title: "success" });
	};

	const changeStatusMutation = useMutationCustom({
		name: `${API_NAME}_changeStatus`,
		url: changeStatus,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessChangeStatusMutation,
	});
	// ---------------------------------------------- logs
	console.log({ data, error, isError, isLoading });
	// ---------------------------------------------- action click table

	const actionsOperation = ({ data, status }) => {
		swal({
			title: "Are you sure?",
			text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				if (status.label === "delete") deleteMutation.mutate({ id: data });
				else changeStatusMutation.mutate({ ids: data, isActive: status.value });
			}
		});
	};

	const clickAction = ({ data, status }) => {
		const newData = data.map((x) => x.id);
		actionsOperation({ data: newData, status });
	};

	const handleAction = (status) => {
		const data = Object.keys(checkList);
		console.log({ data, status });
		actionsOperation({ data, status: status });
	};
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: COMPONENT_NAMES[0], link: newRoute.base },
		{ title: "List", link: newRoute.base },
		{ title: `show ${dataById?.data?.data?.name} hotel` },
	];

	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);

	const buttons = [
		{ title: `back`, link: (previousRoute || newRoute).base + (previousRoute || newRoute).list + `/${years}` + `?${previousQueryParams.toString()}` },
		{
			title: `show hotel`,
			link: newHotelRoute.base + newHotelRoute.edit + "/" + dataById?.data?.data?.id,
			target: "_blank",
		},
	];
	const tableOptions = tableData({
		onClick: clickAction,
		newRoute,
		pathId: "hotel.id",
		active: { show: true },
		disable: { edit: true, delete: true },
	});

	const exportToCSV = ({ apiData, fileName, head, cols }) => {
		const workbook = XLSX.utils.book_new();
		// const myHeader = ["id","name","location"];
		const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e["c"] = head.length - 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);
		worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		worksheet["!cols"] = cols;

		XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
		XLSX.writeFile(workbook, "excel_export.xlsx");

		// const ws = XLSX.utils.json_to_sheet(apiData);
		// /* custom headers */
		// XLSX.utils.sheet_add_aoa(ws, [head], { origin: "A1", });
		// const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		// const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		// const data = new Blob([excelBuffer], { type: fileType });
		// FileSaver.saveAs(data, fileName + fileExtension);
	};

	const handleExcel = () => {
		let cols = [
			{ wch: 30 },
			{ wch: 15 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
		];

		const head = [t("name"), t("priceWithoutKdv"), t("commission"), t("delivery"), t("kdv"), t("orderNumber"), t("date"), t("clock")];
		const apiData = data?.result?.map((y) => {
			const date = moment(y?.createdAt);
			return {
				[t("name")]: y?.name || "Silinen Ürün",
				[t("priceWithoutKdv")]: y?.price,
				[t("commission")]: y?.commission,
				[t("delivery")]: y?.delivery,
				[t("kdv")]: y?.kdv,
				[t("orderNumber")]: y?.orderNumber,
				[t("date")]: date.format("YYYY-MM-DD"),
				[t("clock")]: date.format("HH:mm:ss"),
				// [t("createdAt")]: y?.createdAt,
			};
		});
		exportToCSV({ apiData, fileName: "exc", head, cols });
	};

	//  ----------------------------------------- LOGS ----------------------------------------- //
	console.log({ checkList, data }, "checkList");
	const adminState = useSelector((state) => state.admin);
	const isBranch = adminState.data.isBranch;
	const isHotel = adminState.data.isHotel;
	const isAdmin = adminState.data.userType === "admin";
	return (
		<div>
			<PageTitle title={`${dataById?.data?.data?.name} hotel  lists`} breadcrumbs={breadcrumbs} buttons={buttons} />
			<TableComponent
				{...{
					QueryString,
					data: data?.result,
					tableOptions,
					loading: isLoading,
					search: {
						placeholder: t("search"),
						defaultValue: QueryString.search_text,
					},
					title: `${COMPONENT_NAMES[1]}`,
					// button: button,
					count: data.total,
					pagination: {
						activePage: page,
						pages: Math.ceil(data.total / limit),
						limited: 2,
					},
					checkbox: {
						state: checkList,
						setState: setCheckList,
					},
					actions: {
						onClick: handleAction,
					},
					status: STATUSES,
					dating: true,
					excelButton: {
						show: true,
						disabled: !data?.result,
						onClick: handleExcel,
					},
				}}
			/>
		</div>
	);
};

export default List;
